<template>
  <div class="coverPage A4Container flexCenter">
    <div class="flexCloumnBetween" style="width: 80%;height: 80%;">

      <div>
        <div class='subTitle'>{{ formData.schoolname }}</div>
        <div class='title'>毕&nbsp;业&nbsp;生&nbsp;自&nbsp;荐&nbsp;表</div>
      </div>

      <div class="form">


        <div class='formItem flexStart'>
          <div class='label'>学历</div>
          <div class='input'>专科</div>
        </div>
        <div class='formItem flexStart'>
          <div class='label'>专业</div>
          <div class='input'>{{ formData.major_name || '-' }}</div>
        </div>
        <div class='formItem flexStart'>
          <div class='label'>学制</div>
          <div class='input'>五年</div>
        </div>
        <div class='formItem flexStart'>
          <div class='label'>姓名</div>
          <div class='input'>{{ formData.stu_name }}</div>
        </div>





      </div>

      <div>
        <div class='label'>{{ year }} 年 六 月</div>
        <div class='input'>{{ formData.schoolname }}制</div>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: 'zijian01',
  props: {
    stuinfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      formData: {},
      year: '',
    }
  },
  mounted() {
    let _this = this
    var d = new Date();
    var y = d.getFullYear();
    this.year = this.getYearStr(y)
    this.init()
  },
  methods: {
    init() {
      let _this = this
      let info = JSON.parse(JSON.stringify(_this.stuinfo))
      if (info.stu_name) {
        _this.formData = info
      } else {
        setTimeout(function () {
          _this.init()
        }, 400)
      }
    },
    getYearStr(year) {
      // 定义表示汉字的数组
      var arr = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十']

      // 1,获取表示年份的4位数值
      // 第一个数值
      var a = parseInt(year / 1000)
      // 第二个数值
      var b = parseInt((year % 1000) / 100)
      // 第三个数值
      var c = parseInt((year % 100) / 10)
      // 第四个数值
      var d = year % 10

      return `${arr[a]}${arr[b]}${arr[c]}${arr[d]}`

    }
  }
}
</script>


<style lang='less' type='text/less' scoped>
.coverPage {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 40px;
  text-align: center;
  font-size: 20px;
  box-sizing: border-box;

  .title {
    font-size: 30px;
    margin-top: 50px;
    font-weight: 700;
  }

  .form {
    width: 80%;
    line-height: 40px;

    .formItem {
      margin-top: 10px;
    }

    .label {
      display: inline-block;
      width: 100px;
      text-align: justify;
      text-align-last: justify;
    }

    .input {
      margin-left: 10px;
      flex: 1;
      border-bottom: 1px solid #303133;
      height: 40px;
    }
  }
}
</style>
