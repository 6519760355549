<template>
  <div>
    <div class="perInfo A4Container flexCloumnCenter">
      <div class="form">
        <div class="table" style="height: 100%;">
          <el-row class="flexStretch">
            <el-col :span="3">
              <div style="width: 100%;">
                <el-row>
                  <el-col :span="24" class="cell cell50 flexCenter" style="border-left: 0;">姓 名</el-col>
                </el-row>
                <el-row>
                  <el-col :span="24" class="cell cell50 flexCenter" style="border-left: 0;">政治面貌
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24" class="cell cell50 flexCenter" style="border-left: 0;">联系电话
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24" class="cell cell50 flexCenter" style="border-left: 0;">家庭住址
                  </el-col>
                </el-row>

              </div>
            </el-col>
            <el-col :span="21" class='flexCloumn'>
              <el-row class='flexStretch'>
                <el-col :span="20">
                  <div style="width: 100%;">
                    <el-row>
                      <el-col :span="4" class="cell cell50 flexCenter">{{ formData.stu_name }}</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter">性 别</el-col>
                      <el-col :span="5" class="cell cell50 flexCenter">
                        {{ formData.gender_label || '-' }}
                      </el-col>
                      <el-col :span="4" class="cell cell50 flexCenter">民 族</el-col>
                      <el-col :span="7" class="cell cell50 flexCenter">{{ formData.minzu||'-' }}</el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="4" class="cell cell50 flexCenter">{{ formData.zzmm || '-' }}</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter">出生年月</el-col>
                      <el-col :span="5" class="cell cell50 flexCenter">{{ formData.birthday }}</el-col>
                      <el-col :span="4" class="cell cell50 flexCenter">籍 贯</el-col>
                      <el-col :span="7" class="cell cell50 flexCenter"
                        style='padding: 3px;text-align: center;line-height: 100%;height:50px;overflow: hidden'>
                        <div id='setHometownSize'>{{ formData.jiguan || '-' }}</div>
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="8" class="cell cell50 flexCenter">{{ formData.phone }}</el-col>
                      <el-col :span="5" class="cell cell50 flexCenter">邮箱</el-col>
                      <el-col :span="11" class="cell cell50 flexCenter">{{ formData.dzyx }}</el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="4" class="cellMore flexCenter">
                  <div class="flexCenter" v-if="formData.photo"
                    style="width: 100%;height: 150px;box-sizing:border-box;padding:1px;overflow: hidden">
                    <img v-if="formData.photo" :src="formData.photo" alt=""
                      style="width: 100%;">
                  </div>
                  <span v-else>照 片</span>
                </el-col>
              </el-row>


              <el-row>
                <el-col :span="24" class="cell cell50 flexCenter">{{ formData.jtzz }}
                </el-col>
              </el-row>
            </el-col>
          </el-row>

          <div style="width: 100%;height: calc( 100% - 205px );">
            <el-row>
              <el-col :span="24" class="cell cell30 flexCenter" style="border-left: 0;font-weight: 700;">
                个 人 小 结
              </el-col>
            </el-row>

            <el-row style="height: calc( 40% - 30px )">
              <el-col :span="24" class="cell textIndent2"
                style="border-left: 0;height: 100%;padding: 10px;">{{ formData.summary }}</el-col>
            </el-row>

            <el-row>
              <el-col :span="24" class="cell cell30 flexCenter" style="border-left: 0;font-weight: 700;">
                班 主 任 推 荐 意 见
              </el-col>
            </el-row>

            <el-row style="height: calc( 30% - 30px )">
              <el-col :span="24" class="cell textIndent2"
                style="border-left: 0;height: 100%;padding: 10px;">{{ formData.remark }}</el-col>
            </el-row>


            <el-row class="" style="height: calc( 30% - 0px );">
              <el-col :span="2" class="cell flexCenter" style="height: 100%;border-left: 0;font-weight: 700;">
                奖<br />励<br />或<br />处<br />分
              </el-col>
              <el-col :span="22" class="cell flex_skill" style="height: 100%;padding: 10px;display: inline-block;">
                <div v-for='( item, index) in formData.awards'
                  style='width: 100%;display: inline-block;height: 20px;line-height: initial;'>
                  <div style='width: 100%;'>{{ index + 1 }}. {{ item||'-' }}</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    stuinfo: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      isShowModal: true,
      formData: {}

    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let _this = this
      let info = JSON.parse(JSON.stringify(_this.stuinfo))
      if (info.photo) {
        info.photo = info.photo.split("?")[0] + "?x-oss-process=image/resize,w_200"
      }
      _this.formData = info
      if (info.stu_name) {
        _this.$nextTick(() => {
          _this.resizeA()
        })
      } else {
        setTimeout(function () {
          _this.init()
        }, 400)
      }
    },
    resizeA() {
      let divHeight = 50
      let textHeight = document.getElementById(`setHometownSize`).offsetHeight
      console.log('a', divHeight, textHeight)
      let i = 1
      while (textHeight > divHeight && i < 6) {
        document.getElementById(`setHometownSize`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`setHometownSize`).offsetHeight
        i++
      }
    }
  }
}
</script>

<style lang="less" type="text/less">
.perInfo .el-col {
  display: flex;
  padding: 0;
  margin: 0;
}

.perInfo .el-row {
  width: 100%;
  padding: 0;
  margin: 0;
}
</style>
<style scoped="scoped" type="text/less" lang="less">
.flexStretch {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.perInfo {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 25px 0;

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: calc(100% - 50px);
    height: calc(100% - 0px);
    border: 1px solid #000;
    border-top: 0;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: break-spaces;
      word-break: break-all;
      overflow: hidden;
    }

    .el-row {
      //border-top: 1px solid #000;
      //border-bottom: 1px solid #000;
    }
  }

  .col-row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}


.perInfo {

  .table {
    width: 100%;
  }

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .FS18 {
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .cell {
    border-left: 1px solid #333;
    border-top: 1px solid #333;

    min-height: 30px;
    //line-height: 30px;
    //text-align: center;
    white-space: nowrap;
    font-size: 14px;
    font-family: "宋体";
    word-break: break-all;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-family: "宋体";
  }

  .cellMore {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    min-height: 50px;
    text-align: left;
    font-size: 14px;
    font-family: "宋体";
  }
}

.uline {
  text-decoration: underline;
}
</style>
