<template>
  <div>
    <div class="perInfo A4Container flexCloumnCenter">
      <div class='flexCenter bold' style='height: 50px;'>专 业 证 书 附 件</div>
      <div v-if="formData && formData.length > 0" class="form" style=" border-top: 1px solid #000;">
        <div class="table" style="height: calc( 100% - 50px );">
          <div v-for='(item, index) in formData' :key='index' style="width: 98%;height: calc( 98% / 2 );margin: 1% 1% 0 1%;">
            <div style='width: 100%;height:20px;'> {{ index+1 }}.{{ item.name || '-' }}</div>
            <img :src='item.file' alt='' style='max-width: 100%;max-height: calc( 100% - 20px );'>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    stuinfo: {
      type: Array,
      default: () => {
        return []
      }
    },

  },
  data() {
    return {
      isShowModal: true,
      formData: {},
      scoreList: [],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let _this = this
      let info = JSON.parse(JSON.stringify(_this.stuinfo))
      _this.formData = info

      // console.log(118,score)
      if (info && info.length > 0) {

      } else {
        setTimeout(function () {
          _this.init()
        }, 400)
      }
    },
  }
}
</script>

<style lang="less" type="text/less">
.flex_skill {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.perInfo .el-col {
  display: flex;
  padding: 0;
  margin: 0;
}

.perInfo .el-row {
  width: 100%;
  padding: 0;
  margin: 0;
}
</style>
<style scoped="scoped" type="text/less" lang="less">
.flexStretch {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.perInfo {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 25px 0;

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    border: 1px solid #000;
    border-top: 0;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: break-spaces;
      word-break: break-all;
      overflow: hidden;
    }

    .el-row {
      //border-top: 1px solid #000;
      //border-bottom: 1px solid #000;
    }
  }

  .col-row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}


.perInfo {

  .table {
    width: 100%;
  }

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .FS18 {
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .cell {
    border-left: 1px solid #333;
    border-top: 1px solid #333;

    min-height: 30px;
    //line-height: 30px;
    //text-align: center;
    white-space: nowrap;
    font-size: 14px;
    font-family: "宋体";
    word-break: break-all;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-family: "宋体";
  }

  .cellMore {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    min-height: 50px;
    text-align: left;
    font-size: 14px;
    font-family: "宋体";
  }
}

.uline {
  text-decoration: underline;
}
</style>
